import * as yup from 'yup'

const MAX_SHORT_TEXT_LENGTH = 255

const contactFormSchema = yup
  .object({
    search_term: yup.string().max(MAX_SHORT_TEXT_LENGTH).required(),
    email: yup
      .string()
      .email('Email needs to be a valid email address')
      .max(MAX_SHORT_TEXT_LENGTH)
      .required('Email is required'),
    first_name: yup
      .string()
      .max(MAX_SHORT_TEXT_LENGTH, 'Name must be at most 255 characters')
      .required('Name is required'),
    last_name: yup
      .string()
      .max(MAX_SHORT_TEXT_LENGTH, 'Surname must be at most 255 characters')
      .required('Surname is required'),
    opt_in: yup.boolean(),
  })
  .noUnknown(true)

export type ContactFormDataType = yup.InferType<typeof contactFormSchema>
export default contactFormSchema
