import React from 'react'
import {
  Card,
  FilledButton,
  TextInputField,
  TextInput,
  Heading,
  Display1,
  Section,
  GridWrapper,
  GridItem,
} from '@jsluna/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useMutation } from 'react-query'
import Axios from 'axios'
import contactFormSchema from '@active-kids/common/validation/contactFormSchema'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import './venues.scss'
import config from '../config'
import Loader from '../components/Loader/Loader'

const { emailEndpoint, emailPath } = config

const postForm = async (formData) => {
  const { data } = await Axios.post(`${emailEndpoint}${emailPath}`, formData)
  return data
}

const ContactForm = () => {
  const { register, errors, handleSubmit, reset } = useForm({
    resolver: yupResolver(contactFormSchema),
  })
  const [mutate, { data, status, error }] = useMutation(postForm, {
    onSuccess: () => reset(),
  })

  const onSubmit = (formData) =>
    mutate({
      ...formData,
      opt_in: false,
    })

  return (
    <Layout testid="contact-form-page">
      <SEO title="Keep in touch" />
      <Section className="page">
        <Hero />
        <Section className="page_content">
          <GridWrapper>
            <GridItem size="1" className="activities__wrapper">
              <Card className="activities_search__wrapper">
                <Heading level={2} className="ln-u-text-align-center">
                  Keep in touch
                </Heading>
                <Display1
                  element="h3"
                  className="no_clubs_found ln-u-text-align-center"
                >
                  Thank you for your interest in Active Kids! Our holiday clubs
                  have finished for 2019 but if you’d like to hear all Active
                  Kids news and updates, then opt in to our marketing emails by
                  entering your email address below.
                </Display1>
                <Loader show={status === 'loading'} />
                {data && (
                  <h2
                    className="no_clubs_found center-text"
                    data-testid="post-success"
                  >
                    Thank you for signing up for Active Kids updates
                  </h2>
                )}
                {error && (
                  <h2
                    className="no_clubs_found center-text"
                    data-testid="post-error"
                  >
                    Generic error, please try again
                  </h2>
                )}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="no_clubs_form ln-u-margin-bottom*4"
                >
                  <GridWrapper>
                    <GridItem size="1/1">
                      <TextInput
                        placeholder="Name"
                        name="search_term"
                        type="text"
                        defaultValue="Booking closed"
                        innerRef={register}
                        disabled={status === 'loading'}
                        hidden
                      />
                    </GridItem>
                    <GridItem size="1/1">
                      <TextInputField
                        placeholder="Name"
                        label="Name"
                        name="first_name"
                        type="text"
                        innerRef={register}
                        disabled={status === 'loading'}
                        error={errors?.first_name?.message}
                      />
                    </GridItem>
                    <GridItem size="1/1">
                      <TextInputField
                        placeholder="Surname"
                        label="Surname"
                        name="last_name"
                        type="text"
                        innerRef={register}
                        disabled={status === 'loading'}
                        error={errors?.last_name?.message}
                      />
                    </GridItem>
                    <GridItem size="1/1">
                      <TextInputField
                        placeholder="Email"
                        label="Email"
                        name="email"
                        type="email"
                        innerRef={register}
                        disabled={status === 'loading'}
                        error={errors?.email?.message}
                      />
                    </GridItem>
                    <GridItem size="1/1">
                      <FilledButton
                        fullWidth
                        type="submit"
                        disabled={status === 'loading'}
                      >
                        Submit
                      </FilledButton>
                    </GridItem>
                  </GridWrapper>
                </form>
                <Display1
                  element="h3"
                  className="no_clubs_found ln-u-text-align-center"
                >
                  If you’ve previously told us you want to hear from Active Kids
                  but have changed your mind please email
                  <> </>
                  <a href="mailto:activekids_support@sainsburys.co.uk">
                    activekids_support@sainsburys.co.uk
                  </a>
                </Display1>
              </Card>
            </GridItem>
          </GridWrapper>
        </Section>
      </Section>
    </Layout>
  )
}
export default ContactForm
